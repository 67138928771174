import { Component, OnInit } from '@angular/core';
import { Events, LoadingController, ModalController } from '@ionic/angular';
import CustomStore from 'devextreme/data/custom_store';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import Query from 'devextreme/data/query';

@Component({
  selector: 'app-websiteusernotifications',
  templateUrl: './websiteusernotifications.component.html',
  styleUrls: ['./websiteusernotifications.component.scss'],
})
export class WebsiteusernotificationsComponent implements OnInit {
  public page = 'WebsiteUsersNotifications';
  public notificationsDatasource: any = [];
  public refreshGrid = false;
  public pageSize = 10;
  listOfDataSource: any = {};
  pageNumber: number = 0;
  historyData: any[] = [];
  public totalPages: Number = 0;
  public columns = [
    {
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      },
    },
    {
      name: 'Name',
      prop: 'name',
      allowFilteredRow: true,
    },
    { name: 'Email', prop: 'email', allowFilteredRow: true },
    { name: 'Mobile', prop: 'username', allowFilteredRow: true },
    { name: 'Stone', prop: 'stoneName', allowFilteredRow: true },
    { name: 'Notified', prop: 'isNotified', allowFilteredRow: true },
    { name: 'Created Date', prop: 'created_date' },
  ];
  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public event: Events,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.getUserNotifications();
  }

  async getUserNotifications() {
    //await this.configService.showLoader();
    // let res = await this.httpApiService.getWebsiteUserNotifications(
    //   this.pageSize,
    //   this.pageNumber
    // );
    // let data = res.json();
    // if (res.status == 401) {
    // } else {
    //   this.notificationsList = data.data.data;
    //   debugger;
    // }
    // return;
    this.notificationsDatasource = new CustomStore({
      key: 'id',
      load: async (loadOptions: any) => {
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getWebsiteUserNotifications(
            this.pageSize,
            this.pageNumber
          );
          let data = res.json().data;
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast('error', data.error);
          } else if (res.status == 400) {
            await this.configService.showToast('error', data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content).toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements,
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.historyData.length,
            groupCount: this.historyData.length,
          };
        }
      },
    });
    // let res = await this.httpApiService.getWebsiteUserNotifications();
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast('error', data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast('error', data.error);
    // } else {
    //   this.notificationsList = data.data;
    // }
  }
}
